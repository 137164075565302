<template>
  <div
    class="alert z-30 relative rounded-xl shadow-2 px-4 py-2 pl-2"
    :class="{
      'border-warning': alertType === 'warning',
      'border-blue': alertType === 'info',
    }"
    @click="handleClick"
  >
    <div class="flex">
      <div
        class="mr-3 inline-flex self-center text-warning"
        :class="{
          'text-warning': alertType === 'warning',
          'text-blue': alertType === 'info',
        }"
      >
        <slot name="icon">
          <svg
            class="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </slot>
      </div>
      <div class="alert__content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseAlert',
  props: {
    alertType: {
      default: 'info',
      validator: function(value) {
        return ['info', 'warning'].indexOf(value) !== -1;
      },
    },
    to: {
      type: String,
    },
  },
  methods: {
    handleClick() {
      const { to } = this;
      if (to) {
        this.$router.push({ name: to });
      }
    },
  },
};
</script>

<style scoped>
.alert {
  border-left-width: 6px;
}
</style>
