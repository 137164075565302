<template>
  <span
    class="loader inline-flex relative mx-auto my-0"
    :style="{
      width: `${size}px`,
    }"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="25 25 50 50"
      :style="`transform: rotate(-90deg); color: ${color}`"
      class="text-tertiary h-full w-full absolute top-0 bottom-0 left-0 right-0 m-auto"
    >
      <circle
        fill="transparent"
        cx="50"
        cy="50"
        r="20"
        stroke="white"
        :stroke-width="width"
        stroke-dasharray="125.664"
        stroke-dashoffset="0"
        class="v-progress-circular__underlay"
      ></circle>
      <circle
        fill="transparent"
        cx="50"
        cy="50"
        r="20"
        stroke="currentColor"
        :stroke-width="width"
        stroke-dasharray="125.664"
        :stroke-dashoffset="125.664 - (value / 100) * 125.664"
        class="v-progress-circular__overlay"
      ></circle>
    </svg>
    <div class="v-progress-circular__info"></div>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseProgressCircle',
  props: {
    value: {
      type: Number,
      default: 25,
    },
    size: {
      type: Number,
      default: 120,
    },
    width: {
      type: Number,
      default: 3,
    },
    color: {},
  },
});
</script>

<style scoped lang="scss">
$green: #008744;
$blue: #0057e7;
$red: #d62d20;
$yellow: #ffa700;
$white: #eee;

.loader {
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.circular {
  animation: rotate 2s linear infinite;
  transform-origin: center center;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: $red;
  }
  40% {
    stroke: $blue;
  }
  66% {
    stroke: $green;
  }
  80%,
  90% {
    stroke: $yellow;
  }
}
</style>
