
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseProgressCircle',
  props: {
    value: {
      type: Number,
      default: 25,
    },
    size: {
      type: Number,
      default: 120,
    },
    width: {
      type: Number,
      default: 3,
    },
    color: {},
  },
});
