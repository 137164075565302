
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import ConstRouter from '@/constants/constRouter';
import BaseProgressCircle from '@/components/Base/BaseProgressCircle.vue';
import BaseAlert from '@/components/BaseAlert.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { mapGetters } from 'vuex';
import constRouter from '@/constants/constRouter';
import SalaryAdvanceItem from './SalaryAdvance/Item/SalaryAdvanceItem.vue';
import { list, listGen } from '@/apis/ApiBorrower';
import { LoanList } from '@/views/Dashboard/types';
import BaseSkeletonLoader from '@/components/Base/BaseSkeletonLoader.vue';

export default defineComponent({
  name: 'Dashboard',
  components: {
    BaseSkeletonLoader,
    SalaryAdvanceItem,
    DefaultLayout,
    BaseAlert,
    BaseProgressCircle,
  },
  setup() {
    const router = useRouter();
    const handleClick = () => {
      router.push({ name: ConstRouter.LENDER_NOTI.name });
    };

    const handleClickBorrower = () => {
      router.push({ name: ConstRouter.BORROWER_MONEY.name });
    };
    return {
      constRouter,
      backgroundHome: require('@/assets/images/bgHome.png'),
      DEFAULT_AVATAR: require('@/assets/images/avartar.png'),
      handleClick,
      handleClickBorrower,
    };
  },
  data: () => ({
    list: [] as LoanList[],
    showModal: false,
    loading: false,
  }),
  computed: {
    ...mapGetters({
      isDoneEKYC: 'isDoneEKYC',
      user: 'getUser',
    }),
    percentDone(): number {
      if (this.isDoneEKYC) {
        return 100;
      }
      return 50;
    },
    alertEKYC(): boolean {
      return !this.isDoneEKYC;
    },
  },
  mounted() {
    this.loading = true;
    list({ page: 1, limit: 2 })
      .then(({ data }) => {
        this.loading = false;
        if (data.data?.length) {
          this.list.push(...data.data);
        }
      })
      .catch(() => {
        this.loading = false;
      });
    listGen({ page: 1, limit: 2 })
      .then(({ data }) => {
        this.loading = false;
        if (data.data?.length) {
          this.list.push(...data.data);
        }
      })
      .catch(() => {
        this.loading = false;
      });
  },
});
