<template>
  <default-layout
    :showHeader="false"
    :showModal="showModal"
    :showBackBtn="false"
    show-footer
    @modal-close="showModal = false"
  >
    <div class="customers-need relative ">
      <div
        class="customers-need-header bg-no-repeat bg-cover relative flex flex-col items-center text-white"
        :class="{
          'height-312': percentDone >= 100,
        }"
        :style="{
          'background-image': `url(${backgroundHome})`,
        }"
      >
        <div
          class="avatar flex relative flex-col items-center justify-center mt-6"
        >
          <div
            class="img-wrap overflow-hidden block absolute top-1/2 left-1/2 w-4/5 h-4/5"
            :class="{
              'img-box': percentDone >= 100,
            }"
          >
            <img
              :src="
                user.facePhotoImageUrl ? user.facePhotoImageUrl : DEFAULT_AVATAR
              "
              alt="avatar"
              class="w-full"
            />
          </div>
          <base-progress-circle
            v-if="percentDone < 100"
            :value="percentDone"
            :size="120"
            :width="3"
            class="z-30"
          />
        </div>
        <h2
          class="text-white text-center"
          :class="{
            'mt-2': percentDone < 100,
          }"
        >
          <span v-if="percentDone >= 100" class="block text-xl">
            Xin chào
          </span>
          <span
            class="capitalize"
            :class="{
              'text-tertiary pt-1.5 inline-block': percentDone >= 100,
            }"
            >{{ user.fullName }}
          </span>
        </h2>
        <div v-if="percentDone < 100" class="text-center">
          <div>Mức độ hoàn thành</div>
          <div>
            thông tin tài khoản
            <router-link
              :to="{ name: constRouter.DASHBOARD_REGISTER_PROGRESS.name }"
              class="font-bold"
            >
              {{ percentDone }}%
            </router-link>
          </div>
        </div>
      </div>
      <div class="customers-need-content">
        <base-alert
          v-if="alertEKYC"
          :to="constRouter.EKYC.name"
          alert-type="warning"
          class="px-4 bg-white mx-4 mt-6"
        >
          <template #icon>
            <svg
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </template>
          <div class="text-warning">
            Thông tin hồ sơ của bạn chưa được
            <b>định danh</b>, nhấp để
            <b>
              <u>
                <router-link :to="{ name: constRouter.EKYC.name }">
                  định danh ngay
                </router-link>
              </u>
            </b>
          </div>
        </base-alert>
        <div class="p-4 rounded-2xl">
          <div class="text-xl font-medium mb-4 text-center">
            Các khoản ứng gần đây
          </div>
          <div v-if="loading">
            <base-skeleton-loader />
            <base-skeleton-loader />
          </div>
          <div v-else class="">
            <template v-for="(item, index) in list">
              <salary-advance-item
                v-if="index <= 1"
                :key="index"
                :item="item"
                :class="{
                  'mb-4': index < list.length - 1,
                }"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import ConstRouter from '@/constants/constRouter';
import BaseProgressCircle from '@/components/Base/BaseProgressCircle.vue';
import BaseAlert from '@/components/BaseAlert.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { mapGetters } from 'vuex';
import constRouter from '@/constants/constRouter';
import SalaryAdvanceItem from './SalaryAdvance/Item/SalaryAdvanceItem.vue';
import { list, listGen } from '@/apis/ApiBorrower';
import { LoanList } from '@/views/Dashboard/types';
import BaseSkeletonLoader from '@/components/Base/BaseSkeletonLoader.vue';

export default defineComponent({
  name: 'Dashboard',
  components: {
    BaseSkeletonLoader,
    SalaryAdvanceItem,
    DefaultLayout,
    BaseAlert,
    BaseProgressCircle,
  },
  setup() {
    const router = useRouter();
    const handleClick = () => {
      router.push({ name: ConstRouter.LENDER_NOTI.name });
    };

    const handleClickBorrower = () => {
      router.push({ name: ConstRouter.BORROWER_MONEY.name });
    };
    return {
      constRouter,
      backgroundHome: require('@/assets/images/bgHome.png'),
      DEFAULT_AVATAR: require('@/assets/images/avartar.png'),
      handleClick,
      handleClickBorrower,
    };
  },
  data: () => ({
    list: [] as LoanList[],
    showModal: false,
    loading: false,
  }),
  computed: {
    ...mapGetters({
      isDoneEKYC: 'isDoneEKYC',
      user: 'getUser',
    }),
    percentDone(): number {
      if (this.isDoneEKYC) {
        return 100;
      }
      return 50;
    },
    alertEKYC(): boolean {
      return !this.isDoneEKYC;
    },
  },
  mounted() {
    this.loading = true;
    list({ page: 1, limit: 2 })
      .then(({ data }) => {
        this.loading = false;
        if (data.data?.length) {
          this.list.push(...data.data);
        }
      })
      .catch(() => {
        this.loading = false;
      });
    listGen({ page: 1, limit: 2 })
      .then(({ data }) => {
        this.loading = false;
        if (data.data?.length) {
          this.list.push(...data.data);
        }
      })
      .catch(() => {
        this.loading = false;
      });
  },
});
</script>
<style lang="scss" scoped>
.plus-icon {
  line-height: 42px;
  padding: 3px 14px;
  width: 50px;
  height: 50px;
  border: 2px white solid;
}

.customers-need {
  .customers-need-header {
    &.height-312 {
      height: 312px;
    }

    background-position: center bottom;
    height: 330px;

    .avatar {
      width: 120px;
      height: 120px;

      .img-wrap {
        &.img-box {
          box-shadow: 0 0 0 2px #fff;
        }

        border-radius: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;
      }
    }
  }

  .customers-need-content {
    margin-top: -82px;

    &.margin-top--115 {
      margin-top: -88px;
    }

    .btn-group {
      .invest-btn {
        width: calc(50% - 12px);
        background: #feeee7;
      }

      .salaryAdvance {
        background: white;
      }
    }
  }

  @media (max-width: 360px) {
    .customers-need-content {
      margin-top: -64px;
    }
  }
}
</style>
